import {
  createRouter,
  createWebHashHistory,
  useRouter
} from 'vue-router'
import Login from '../views/Account/Login.vue'
import {
  CurrentUser
} from '../helper/user';
import LS from '@/assets/js/ls'
const routes = [{
    path: '/', //首次登录的默认页面
    name: 'Login',
    meta: {
      skipCheckLogin: true
    },
    component: Login
  },
  {
    path: '/chatMain',
    name: "ChatMain",
    redirect:"/chat",
    meta: {
      title: '群聊管理',
      index: "8-1",
      noBack:true,
    },
    component: () => import('../views/ChatManager/ChatMain.vue'),
    children: [{
      path: '/chat',
      name: "Chat",
      meta: {},
      component: () => import('../views/ChatManager/Chat/group-chat.vue')
    }]
  },
  {
    path: '/brandmenu', //首次登录的默认页面
    name: 'Brand',
    component:  () => import('../views/Menu/brand.vue'),
    redirect: '/brand/list',
    children: [{
      path: '/brand/list',
      name: "BrandList",
      meta: {
        title: '品牌列表',
        noBack:true,
      },
      component: () => import('../views/BrandManager/BrandList.vue')
    },{
      path: '/brand/add',
      name: "AddBrand",
      meta: {
        title: '添加品牌',
      },
      component: () => import('../views/BrandManager/AddBrand.vue')
    },{
      path: '/brand/basesetting',
      name: "BaseSetting",
      meta: {
        title: '基本配置',
      },
      component: () => import('../views/BrandManager/BaseSetting.vue')
    },{
      path: '/brand/paramsetting',
      name: "ParamSetting",
      meta: {
        title: '参数配置',
      },
      component: () => import('../views/BrandManager/ParamSetting.vue')
    },{
      path: '/user/list',
      name: "UserList",
      meta: {
        title: '操作人员',
      },
      component: () => import('../views/BrandManager/UserList.vue')
    },{
      path: '/user/add',
      name: "AddUser",
      meta: {
        title: '添加成员',
      },
      component: () => import('../views/BrandManager/AddUser.vue')
    },{
      path: '/user/edit',
      name: "EditUser",
      meta: {
        title: '编辑成员',
      },
      component: () => import('../views/BrandManager/EditUser.vue')
    }]
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('../views/Menu/index.vue'),
    redirect: '/groupsend',
    children: [{
        path: '/groupsend',
        name: "GroupSend",
        meta: {
          title: '群聊发送',
          index: "1-1",
          noBack:true,
        },
        component: () => import('../views/GroupTool/GroupSend/GroupSend.vue')
      }, {
        path: '/groupsend/create',
        name: "CreateGroupSend",
        meta: {
          title: '新建群发',
          index: "1-1",
        },
        component: () => import('../views/GroupTool/GroupSend/CreateGroupSend.vue')
      }, {
        path: '/singlesend',
        name: "SingleSend",
        meta: {
          title: '单聊发送',
          index: "1-2",
          noBack:true,
        },
        component: () => import('../views/GroupTool/SingleSend/SingleSend.vue')
      }, {
        path: '/singlesend/create',
        name: "CreateSingleSend",
        meta: {
          title: '新建单聊',
          index: "1-2",
        },
        component: () => import('../views/GroupTool/SingleSend/CreateSingleSend.vue')
      }, {
        path: '/grouppublish/list',
        name: "PublishList",
        meta: {
          title: '群公告',
          index: "1-3",
          noBack:true,
        },
        component: () => import('../views/GroupTool/GroupPublish/PublishList.vue')
      }, {
        path: '/grouppublish/publish',
        name: "Publish",
        meta: {
          title: '发布群公告',
          index: "1-3",
        },
        component: () => import('../views/GroupTool/GroupPublish/Publish.vue')
      },
      {
        path: '/syncgrouplist',
        name: "SyncGroupList",
        meta: {
          title: '企业标签',
          index: "1-4",
          noBack:true,
        },
        component: () => import('../views/GroupTool/Sync/SyncGroupList.vue')
      },
      {
        path: '/syncgroupdetail',
        name: "SyncGroupDetail",
        meta: {
          title: '标签组内情况',
          index: "1-4",
        },
        component: () => import('../views/GroupTool/Sync/SyncGroupDetail.vue')
      },
      {
        path: '/groupnew',
        name: "GroupNew",
        meta: {
          title: '新群开通',
          index: "2-2",
          noBack:true,
        },
        component: () => import('../views/GroupManager/GroupNew/GroupNew.vue')
      },
      {
        path: '/groupdetail',
        name: "GroupDetail",
        meta: {
          title: '群列表',
          index: "2-1",
          noBack:true,
        },
        component: () => import('../views/GroupManager/GroupDetail/GroupDetail.vue')
      },
      {
        path: '/groupinfo',
        name: "GroupInfo",
        meta: {
          title: '群明细',
          index: "2-1",
        },
        component: () => import('../views/GroupManager/GroupDetail/GroupInfo.vue')
      },
      {
        path: '/groupnameinfo',
        name: "GroupNameInfo",
        meta: {
          title: '群名称修改明细',
          index: "2-1",
        },
        component: () => import('../views/GroupManager/GroupDetail/GroupNameInfo.vue')
      },
      {
        path: '/groupdetail/memberlist',
        name: "MemberList",
        meta: {
          title: '成员列表',
          index: "2-1",
        },
        component: () => import('../views/GroupManager/GroupDetail/MemberList.vue')
      },
      {
        path: '/grouptag',
        name: "GroupTag",
        meta: {
          title: '群标签',
          index: "2-3",
          noBack:true,
        },
        component: () => import('../views/GroupManager/GroupTag/GroupTag.vue')
      },
      {
        path: '/autogrouptag',
        name: "AutoGroupTag",
        meta: {
          title: '自动打群标签',
          index: "2-3",
          noBack:false,
        },
        component: () => import('../views/GroupManager/GroupTag/AutoTag.vue')
      },
      {
        path: '/addtagrule',
        name: "AddTagRule",
        meta: {
          title: '新建规则',
          index: "2-3",
          noBack:false,
        },
        component: () => import('../views/GroupManager/GroupTag/AddTagRule.vue')
      },
      {
        path: '/groupsetting',
        name: "GroupSetting",
        meta: {
          title: '群聊邀请设置',
          index: "2-4",
          noBack:true,
        },
        component: () => import('../views/GroupManager/GroupSetting/GroupSetting.vue')
      },
      {
        path: '/groupadd',
        name: "GroupAdd",
        meta: {
          title: '助手入群',
          index: "2-5",
          noBack:true,
        },
        component: () => import('../views/GroupManager/GroupAdd/GroupAdd.vue')
      },
      {
        path: '/groupeditlog',
        name: "GroupEditLog",
        meta: {
          title: '操作日志',
          index: "2-5",
        },
        component: () => import('../views/GroupManager/GroupAdd/GroupEditLog.vue')
      },
      {
        path: '/grouphelperdetail',
        name: "GroupHelperDetail",
        meta: {
          title: '群明细',
          index: "2-5",
        },
        component: () => import('../views/GroupManager/GroupAdd/GroupHelperDetail.vue')
      },
      {
        path: '/groupautowrap',
        name: "GroupAutoWrap",
        meta: {
          title: '自动回复',
          index: "2-6",
          noBack:true,
        },
        component: () => import('../views/GroupManager/GroupAuto/GroupAutoWrap.vue')
      },
      {
        path: '/createautomission',
        name: "CreateAutoMission",
        meta: {
          title: '添加回复任务',
          index: "2-6",
        },
        component: () => import('../views/GroupManager/GroupAuto/CreateAutoMission.vue')
      },
      {
        path: '/harassrule',
        name: "HarassRule",
        meta: {
          title: '防骚扰规则',
          index: "2-7",
          noBack:true,
        },
        component: () => import('../views/GroupManager/GroupHarass/HarassRule.vue')
      },
      {
        path: '/groupwelcome',
        name: "GroupWelcome",
        meta: {
          title: '入群欢迎语',
          index: "2-8",
          noBack:true,
        },
        component: () => import('../views/GroupManager/GroupWelcome/GroupWelcome.vue')
      },
      {
        path: '/createwelcome',
        name: "CreateWelcome",
        meta: {
          title: '添加入群欢迎语',
          index: "2-8",
        },
        component: () => import('../views/GroupManager/GroupWelcome/CreateWelcome.vue')
      },
      {
        path: '/robotpage',
        name: "RobotPage",
        meta: {
          title: '助手列表',
          index: "4-1",
          noBack:true,
        },
        component: () => import('../views/RobotManager/RobotPage.vue')
      },
      {
        path: '/robotpage/createrobot',
        name: "CreateRobot",
        meta: {
          title: '添加平台助手',
          index: "4-1",
        },
        component: () => import('../views/RobotManager/CreateRobot.vue')
      },
      {
        path: '/abandoned',
        name: "Abandoned",
        meta: {
          title: '废弃账号列表',
          index: "4-1",
          noBack:false,
        },
        component: () => import('../views/RobotManager/AbandonedList.vue')
      },
      {
        path: '/companymember',
        name: "CompanyMember",
        meta: {
          title: '企业成员',
          index: "4-2",
          noBack:true,
        },
        component: () => import('../views/RobotManager/CompanyMember.vue')
      },
      {
        path: '/materiallib',
        name: "MaterialLib",
        meta: {
          title: '素材库',
          index: "5-1",
          noBack:true,
        },
        component: () => import('../views/MaterialManager/MaterialLib.vue')
      },
      {
        path: '/minibook',
        name: "MiniBook",
        meta: {
          title: '更新日志',
          index: "5-2",
          noBack:true,
        },
        component: () => import('../views/MaterialManager/MiniBook.vue')
      },
      {
        path: '/miniprogram',
        name: "MiniProgram",
        meta: {
          title: '素材来路',
          index: "5-3",
          noBack:true,
        },
        component: () => import('../views/MaterialManager/MiniProgram.vue')
      },
      {
        path: '/accountmanager',
        name: "AccountManger",
        meta: {
          title: '账号管理',
          index: "8-1",
          noBack:true,
        },
        component: () => import('../views/SettingManager/Account/AccountManger.vue')
      }, {
        path: '/addaccount',
        name: "AddAccount",
        meta: {
          title: '添加账号',
          index: "8-1",
        },
        component: () => import('../views/SettingManager/Account/AddAccount.vue')
      }, {
        path: '/powermanager',
        name: "PowerManager",
        meta: {
          title: '权限管理',
          index: "8-2",
          noBack: true,
        },
        component: () => import('../views/SettingManager/Power/PowerManager.vue')
      },{
        path: '/whitelist',
        name: "WhiteList",
        meta: {
          title: '白名单用户',
          index: "8-3",
          noBack: true,
        },
        component: () => import('../views/SettingManager/White/WhiteList.vue')
      },{
        path: '/dailylist',
        name: "DailyList",
        meta: {
          title: '迭代日志',
          index: "8-4",
          noBack: true,
        },
        component: () => import('../views/SettingManager/Daily/DailyList.vue')
      },{
        path: '/actionloglist',
        name: "ActionLogList",
        meta: {
          title: '操作日志',
          index: "8-4",
          noBack: true,
        },
        component: () => import('../views/SettingManager/ActionLog/ActionLogList.vue')
      }
    ],
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : "233私域管家助手";
  if (to.query.SID && to.meta.title.indexOf("添加") > -1) {
    to.meta.title = to.meta.title.replace("添加", "编辑")
  } else if ((to.query.SID) && to.meta.title.indexOf("新建") > -1) {
    to.meta.title = to.meta.title.replace("新建", "编辑")
  }else if ((to.query.SID) && to.meta.title.indexOf("发布") > -1) {
    to.meta.title = to.meta.title.replace("发布", "编辑")
  }
  if (!to.meta.skipCheckLogin) {
    let Uid = LS.get('userInfo')?.Uid || '';//用户id
    if(Uid){ //用户名 是否存在 存在下一步
      next();
    } else { //不存在 前往登录
      next('/')
    }
  } else {
    next();
  }
})
export default router