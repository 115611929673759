import { createStore } from 'vuex'
import LS from '@/assets/js/ls'
import GroupSend from './modules/GroupSend'
import SingleSend from './modules/SingleSend'
import SyncGroupList from './modules/SyncGroupList'
import GroupDetail from './modules/GroupDetail'
import GroupAdd from './modules/GroupAdd'
import GroupWelcome from './modules/GroupWelcome'
import AccountManger from './modules/AccountManger'
import GroupNameInfo from './modules/GroupNameInfo'

const key = 'userInfo'
const roleKey='roleList'
const verKey='version'
export default createStore({
  modules:{
    GroupSend,
    SingleSend,
    SyncGroupList,
    GroupDetail,
    GroupAdd,
    GroupWelcome,
    AccountManger,
    GroupNameInfo
  },
  state: {
    userInfo:{
      LoginName:'',
      BrandID:0,
      Token:"",
      Role:0,
      Uid:0
    },
    version:"",
    roleList:[],
    publishInfo:{
      //0：当前公告 1.发布公告
      theme:0
    },
    autoReplay:{
      //群聊 1vs1
      type:0
    },
    //聊天socket的信息
    chatInfo:{}
  },
  getters: {
    userInfo: state=> {
      if (state.userInfo.LoginName == '') {
        state.userInfo = LS.get(key)
      }
      return state.userInfo
    },
    version: state=> {
      if (state.version == '') {
        state.version = LS.get(verKey)
      }
      return state.version
    },
    roleList: state=> {
      if (!state.roleList||state.roleList.length<=0) {
        state.roleList = LS.get(roleKey)
      }
      return state.roleList
    },
    publishInfo:state=>{
      if(!state.publishInfo.theme){
        state.publishInfo.theme = 0;
      }
      return state.publishInfo;
    },
    autoReplay:state=>{
      if(!state.autoReplay.type){
        state.autoReplay.type = 0
      }
      return state.autoReplay
    },
    chatInfo:state=>{
      return state.chatInfo;
    }
  },
  mutations: {
    setUserInfo(state,userInfo){
      console.log(userInfo,'userInfo')
      for(let o in userInfo.User){
        state.userInfo[o] = userInfo.User[o];
      }
      state.roleList = userInfo.Role;
      state.version=userInfo.Version
      LS.put(key,userInfo.User,1)
      LS.put(roleKey,userInfo.Role,1)
      LS.put(verKey,userInfo.Version,1)
    },
    
    setPublishInfo(state,theme){
      state.publishInfo.theme = theme||0;
    },
    setAutoReplay(state,type){
      state.autoReplay.type = type||0;
    },
    setChatInfo(state,options){
      state.chatInfo = options||{};
    },
    clearInfo(state,info){
      LS.clear(key)
      state.userInfo={}
      LS.clear(roleKey)
      state.roleList=[]
      LS.clear(verKey)
      state.version=''

    }
  },
  actions: {
    SETUSERINFO(context,userInfo){
      context.commit('setUserInfo',userInfo)
    },
    CLEARINFO(context,info){
      context.commit('clearInfo')
    }
  },
})
