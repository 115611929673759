import axios from 'axios'
import crypto from '@/assets/js/crypto.js'
import LS from '@/assets/js/ls'
import { ElLoading,ElMessage,ElMessageBox} from 'element-plus'
import store from '../../store';

const $axios = axios.create({
    baseURL: process.env.VUE_APP_URL,
    timeout: 20000, //响应时长可根据业务需求自行设置
    responseType: "json",//请求数据类型包括
    headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
    }
})
let loadingInstance = null
//请求拦截器
$axios.interceptors.request.use(config => {
    if(config.data&&!config.data.skipToast){ //有些页面要循环调用
        loadingInstance = ElLoading.service({ fullscreen: true ,text:'请等待...'})
    }
    let Data = crypto.encrypt(JSON.stringify(config.data))
    console.log('========request========>',config.url)
    console.log(JSON.stringify(config.data))
    if (config.url.indexOf("BatchDownload") > -1) {
        config.timeout = 6000000
    }
    //如果是FormData 修改请求头
    if(config.data instanceof FormData){
        config.headers['Content-Type'] = 'multipart/form-data';
        Data = config.data;
    }
    //下载特别接口处理
    if(config.url.indexOf("CreateOrderData")>0||config.url.indexOf("DownLoadDispatch")>0){
        config.responseType = 'blob';
    } 
    config.data = Data;
    config.headers['Token']= LS.get("userInfo")?LS.get("userInfo").Token||"":""
    if (config.method == 'get') {
        // get请求加上时间戳
        config.params = {
            _t: Date.parse(new Date()) / 1000,
            ...config.params
        }
    }
    return config
}, error => {
    return Promise.reject(error)
});

//响应拦截器
$axios.interceptors.response.use(response => {
    if(loadingInstance){
        loadingInstance.close()
    }
    if (response.status === 200) {
        if(response.request.responseType == 'blob'){
            return response
        }
        
        if(!response.data.IsSuccess){   //失败
            if(response.data.ErrorCode == 1015 || response.data.ErrorCode == 1016){
                ElMessage.closeAll()
                ElMessage('账号异常,请重新重登');
                store.dispatch('CLEARINFO');
            } if(response.data.ErrorCode == 100401){
                ElMessage.closeAll()
                store.dispatch('CLEARINFO');
                ShowErr(response)
            } else {
                ElMessage.closeAll()
                ElMessage.error(response.data.Message)
                return Promise.reject(response.data.Message)
            }
        }else{
            return response.data.ResultData
        }
    }
}, error => {
    if(loadingInstance){
        loadingInstance.close()
    }
    if (error && error.response) {
        if(error.response.status == 401){
            ElMessage.closeAll()
            ElMessage(error.response.data.Message);
            store.dispatch('CLEARINFO');
        }  if(error.response.data.ErrorCode == 100401){
            ElMessage.closeAll()
            store.dispatch('CLEARINFO');
            ShowErr(error.response)
        } else {
            ElMessage.closeAll()
            ElMessage(error.response.data.Message);
            return Promise.reject(error)
        }
    } else {
        return Promise.reject(error)
    }
    
    
})

function ShowErr(response){
    ElMessageBox.confirm("已有另一个账号登录", "提示", {
        confirmButtonText: "我知道了",
        showCancelButton:false,
        type: "error",
      }).then(() => {
        if(response.config.url.indexOf("/account/login")<0){
            window.location.href=""
        }
    }).catch(() => {
          
    });
}

export default $axios