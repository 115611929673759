export default {
    namespaced:true,
    state:{
        pageObj:null,
        searchOption:null
    },
    getters:{

    },
    mutations:{
        setData(state,data){
            state.pageObj = data.pageObj
            state.searchOption = data.searchOption
        },
    },
    actions:{

    },
}