export default {
    namespaced:true,
    state:{
        pageObj:null,
    },
    getters:{

    },
    mutations:{
        setData(state,data){
            state.pageObj = data.pageObj
        },
    },
    actions:{

    },
}