<template>
  <div class="login-warp">
    <img class="bg" src="@/assets/imgs/background.png" alt="">
    <img class="bg-logo" src="@/assets/imgs/logo_h_white.png" alt="">
    <div class="login">

      <div class="input-box">
          <div class="top-wrap">
            <div class="banner-tip">
              <p>登 录</p>
              <div class="line"></div>
            </div>
            <div>

            </div>
            <!-- <img class="banner-logo" src="@/assets/imgs/logo_v_white.png" alt=""> -->
          </div>

          <div class="input-login" >
            <el-form :model="userForm" ref="ruleFormRef" :rules="rules" style="margin:20px 0 0 10px;">
              <el-form-item prop="LoginName">
                <div class="input-wrap">
                  <p>用户名</p>
                  <el-input class="input-fix" v-model="userForm.LoginName" placeholder="请输入用户名称"></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="UserPwd">
                <div class="input-wrap">
                  <p>密码</p>
                  <el-input class="input-fix"  v-model="userForm.UserPwd" type="password" placeholder="请输入登录密码"></el-input>
                </div>
                
                </el-form-item>
              <br>
              <el-form-item  >
                <div class="login-btn" @click="submit('ruleForm')">登 录</div>
              </el-form-item>
            </el-form>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive,ref,unref,onMounted,getCurrentInstance} from "vue";
import {useRoute,useRouter} from "vue-router";
import { useStore } from "vuex";
import {Login} from '../../helper/user'

export default {
  name: "Login",
  setup(){
    const router = useRouter()
    const store = useStore()
    const {proxy} = getCurrentInstance()
    const ruleFormRef = ref(null)
    const userForm = ref({//用户登录信息
      LoginName:'',
      UserPwd:''
    })
    const rules = {
      LoginName:[
        { required: true, message: '请输入用户名', trigger: 'blur' },
      ],
      UserPwd:[
        { required: true, message: '请输入密码', trigger: 'blur' },
      ]
    }

    onMounted(()=>{
      userForm.value.UserName = proxy.$cookie.get('LoginName') || '';
    })

    /*****************点击事件*********************/
    const submit = async ()=>{//提交表单
      const form = unref(ruleFormRef);
      if (!form) return
      try{
        await form.validate()
        //请求登录接口
        let apiOption = {
          LoginName:userForm.value.LoginName,
          Password:userForm.value.UserPwd
        }
        Login(apiOption).then(res=>{
          console.log(res);
          dealwithLogin(res);
          if(res.User.Role==99){
            router.replace({path:'./brandmenu'})
          } else {
            router.replace({path:'./menu'})
          }
        })
        
      }catch(error){
        console.log(error)
      }
    }

    //储存账号信息
    const dealwithLogin=(res)=>{   
      store.dispatch('SETUSERINFO',res);
      // proxy.$cookie.set('LoginName',userForm.value.LoginName,1*30);//单位 1天 * 30 
    }

    //储存权限信息

    return {
        submit,
        ruleFormRef,
        userForm,
        rules,
        dealwithLogin
    }
  },
};
</script>
<style scoped lang="scss">
 .login-warp {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #E0E6EB;
    overflow: hidden;
    .bg{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .bg-logo{
      width: 10%;
      position: absolute;
      left: 60px;
      top: 60px;
    }
  }
  .login {
    width: 450px;
    height: 480px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 80px;
    box-sizing: border-box;
    box-shadow: 1px -1px 10px rgba(0, 0, 0, 0.3);
  }
  .input-box .el-input{
    width: 200px;
  }

  .top-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    font-size: 18px;
    
    .banner-logo{
      width: 60px;
      // height: 40px;
    }

    .banner-tip{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;

      p{
        font-weight: 600;
      }
      .line{
        margin-top: 5px;
        width: 100%;
        height: 8px;
        background-color: #000;
        border-radius: 4px;
      }

    }
  }

  .login-btn{
    width: 100%;
    height: 36px;
    padding: 20px auto;
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    text-align: center;
    line-height: 36px;
    font-weight: bold;
    font-size: 16px;
    margin-top: 50px;
    cursor: pointer;

  }

  .input-wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
    color: $color-text;
    p{
      margin-bottom: 5px;
    }
  }

  :deep().input-box .el-input{
    width: 100%;
    font-size: 14px;
  }
  :deep().el-input__inner{
    background-color: #F6F6F6 !important; 
    border: none;
  }
  :deep().el-input--mini .el-input__inner{
    background-color: #F6F6F6 !important; 
    height: 32px;
    line-height: 32px;
  }
  // :deep() input:-webkit-autofill {
  //   box-shadow: 0 0 0px 1000px #F6F6F6 inset !important
  // }

</style>
