<template>
  <div id="nav" v-cloak>
    <el-config-provider :locale="locale">
      <router-view/>
    </el-config-provider>
  </div>
</template>


<script>
import { ElConfigProvider } from 'element-plus'

import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default({
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
    }
  },
})
</script>

<style lang="scss">
@import '@/assets/css/base.scss';
@import '@/assets/css/index.scss';

</style>
