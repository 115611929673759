import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import cookie from 'vue-cookie'


let app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus,{size:'mini'});
app.config.globalProperties.$axios=axios
app.mount('#app');

app.config.globalProperties.$cookie= cookie
