import CryptoJS from 'crypto-js'

export default {//加密
  encrypt(word){
    var keyStr =process.env.VUE_APP_KEY;
    var keyIV =process.env.VUE_APP_VI;
    var key  = CryptoJS.enc.Utf8.parse(keyStr);//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
  },

  MD5(signStr){
      return CryptoJS.MD5(signStr).toString();
  }

}