import $axios from '../assets/js/request.js'
import crypto from '../assets/js/crypto.js'
let baseURL = process.env.VUE_APP_URL

//登录
export function Login(data) {
  return $axios.post(`${baseURL}/User/Login`,data);

}

//登出
export function Logout(data) {
  return $axios.post(`${baseURL}/User/Logout`,data);

}
